import React, { PureComponent } from 'react';
import { Button, Form, Select, DatePicker, Collapse } from 'antd';
import service from '../../core/services/admin';
import styles from './filters-sidebar.module.css';
import moment from 'moment';
import locale from 'antd/lib/date-picker/locale/es_ES';

const FormItem = Form.Item;

class FiltersSidebarActivity extends PureComponent {
    state = {
        companies: [],
        courses: [],
        areas: [],
        areasFetched: false,
    };

    async componentDidMount() {
        const { results: companies } = await service.getCompanies();
        this.setState({ companies });
    }

    handleCompanyChange = async (value) => {
        const company = this.state.companies.find((c) => c.id === value);
        this.setState({ courses: company.courses });
        const areas = await service.getAreas(company.uuid);
        this.setState({ areas, areasFetched: true });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.props.onFilter(values);
            }
        });
    };

    render() {
        const { buttonText, form, fetching } = this.props;
        const { getFieldDecorator } = form;

        return (
            <div className={`content-internal ${styles.filters}`}>
                <Form onSubmit={this.handleSubmit} className={styles.form}>
                    <FormItem help={false}>
                        {getFieldDecorator('company', {
                            rules: [
                                {
                                    required: true,
                                },
                            ],
                        })(
                            <Select
                                placeholder="Compañía"
                                onChange={this.handleCompanyChange}
                            >
                                {(this.state.companies || []).map((c) => (
                                    <Select.Option key={c.id} value={c.id}>
                                        {c.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        )}
                    </FormItem>
                    <FormItem help={false}>
                        {getFieldDecorator('date', {
                            initialValue: moment(),
                            rules: [],
                        })(<DatePicker.MonthPicker locale={locale} />)}
                    </FormItem>
                    <FormItem help={false}>
                        {getFieldDecorator('course', {
                            rules: [],
                        })(
                            <Select placeholder="Curso">
                                {(this.state.courses || []).map((c) => (
                                    <Select.Option key={c.id} value={c.id}>
                                        {c.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        )}
                    </FormItem>
                    <Collapse bordered={false}>
                        <Collapse.Panel header="Otros filtros">
                            <FormItem help={false}>
                                {getFieldDecorator('areas', {
                                    rules: [
                                        {
                                            required: false,
                                        },
                                    ],
                                })(
                                    <Select
                                        placeholder="Áreas"
                                        showArrow={true}
                                        mode="multiple"
                                        maxTagCount={3}
                                        notFoundContent={`${
                                            this.state.areasFetched
                                                ? 'No se encontraron areas'
                                                : 'Seleccione la compañía'
                                        }`}
                                    >
                                        {(this.state.areas || []).map((c) => (
                                            <Select.Option key={c} value={c}>
                                                {c}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                )}
                            </FormItem>
                        </Collapse.Panel>
                    </Collapse>

                    <FormItem>
                        <Button
                            block
                            size="large"
                            type="primary"
                            htmlType="submit"
                            loading={fetching}
                        >
                            {buttonText}
                        </Button>
                    </FormItem>
                </Form>
            </div>
        );
    }
}

export default Form.create()(FiltersSidebarActivity);
