import { createActions, handleActions } from 'redux-actions';

import client from '../core/services/admin';

// INITIAL STATE
const initialState = {
    report_data: [],
    fetching: false,
    error: null,
    courses: [],
    courses_fetching: false,
    courses_error: null,
};

// ACTIONS
const pending = 'PENDING';
const fulfilled = 'FULFILLED';
const rejected = 'REJECTED';

export const actions = createActions(
    {
        GETREPORT: (tenantId, courseId, filters) =>
            client.getGeneralReport(tenantId, courseId, filters),
        GETREPORT2: (tenantId, courseId, filters) =>
            client.getUsersReport(tenantId, courseId, filters),
        GETCOURSES: (query, tenantId, page) =>
            client.getCourses(query, tenantId, 100, 100 * (page - 1)),
        GETACTIVITYREPORT: (tenantId, filters) =>
            client.getActivityReport(tenantId, filters),
    },
    { prefix: 'admin' }
);

// REDUCER
const reducer = handleActions(
    {
        [`${actions.getreport}_${pending}`]: (state) => ({
            ...state,
            fetching: true,
            error: null,
        }),
        [`${actions.getreport}_${fulfilled}`]: (state, { payload }) => ({
            ...state,
            report_data: payload,
            error: null,
            fetching: false,
        }),
        [`${actions.getreport}_${rejected}`]: (state, { payload }) => ({
            ...state,
            error: payload,
            fetching: false,
        }),
        [`${actions.getreport2}_${pending}`]: (state) => ({
            ...state,
            fetching: true,
            error: null,
        }),
        [`${actions.getreport2}_${fulfilled}`]: (state, { payload }) => ({
            ...state,
            report_data: payload,
            error: null,
            fetching: false,
        }),
        [`${actions.getreport2}_${rejected}`]: (state, { payload }) => ({
            ...state,
            error: payload,
            fetching: false,
        }),

        [`${actions.getcourses}_${pending}`]: (state) => ({
            ...state,
            courses_fetching: true,
            courses_error: null,
        }),
        [`${actions.getcourses}_${fulfilled}`]: (state, { payload }) => ({
            ...state,
            courses: payload.results,
            metadata: payload.pagination,
            courses_error: null,
            courses_fetching: false,
        }),
        [`${actions.getcourses}_${rejected}`]: (state, { payload }) => ({
            ...state,
            courses_error: payload,
            courses_fetching: false,
        }),

        [`${actions.getactivityreport}_${pending}`]: (state) => {
            return { ...state, fetching: true, error: null };
        },
        [`${actions.getactivityreport}_${fulfilled}`]: (state, { payload }) => {
            return {
                ...state,
                report_data: payload,
                error: null,
                fetching: false,
            };
        },
        [`${actions.getactivityreport}_${rejected}`]: (state, { payload }) => {
            return { ...state, error: payload, fetching: false };
        },
    },
    initialState
);

export default reducer;
