import BaseService from './baseService';

export default class AdminService extends BaseService {
    static async getCompanies() {
        return await super.request('/sa/tenants?skip=0&limit=100');
    }

    static async getInstructors(tenantId) {
        return await super.request(
            `/sa/tenants/${tenantId}/instructors?skip=0&limit=100`
        );
    }

    static async getAreas(tenantId) {
        return await super.request('/sa/users/areas', 'GET', null, {
            'X-Tenant-Id': tenantId,
        });
    }

    static async getLocations(tenantId) {
        return await super.request('/sa/users/locations', 'GET', null, {
            'X-Tenant-Id': tenantId,
        });
    }

    static async getUsersReport(tenantId, courseId, filters) {
        const filtersQuery = Object.keys(filters).reduce(
            (prev, curr) => `${prev}&${curr}=${filters[curr]}`,
            ''
        );
        return await super.request(
            `/sa/reports/${courseId}/users?${filtersQuery}`,
            'GET',
            null,
            { 'X-Tenant-Id': tenantId }
        );
    }

    static async getActivityReport(tenantId, filters) {
        const filtersQuery = Object.keys(filters).reduce(
            (prev, curr) => `${prev}&${curr}=${filters[curr]}`,
            ''
        );
        return await super.request(
            `/sa/reports/activity?${filtersQuery}`,
            'GET',
            null,
            { 'X-Tenant-Id': tenantId }
        );
    }

    static async getGeneralReport(tenantId, courseId, filters) {
        const filtersQuery = Object.keys(filters).reduce(
            (prev, curr) => `${prev}&${curr}=${filters[curr]}`,
            ''
        );
        return await super.request(
            `/sa/reports/${courseId}/general?${filtersQuery}`,
            'GET',
            null,
            { 'X-Tenant-Id': tenantId }
        );
    }

    static async getCourses(query, tenantId, limit, skip) {
        return await super.request(
            `/sa/courses?q=${query || ''}&tenantId=${
                tenantId || ''
            }&limit=${limit}&skip=${skip}`,
            'GET'
        );
    }

    static async getCourse(courseId) {
        return await super.request(`/sa/courses/${courseId}`, 'GET');
    }

    static async getCourseCategories(courseId) {
        return await super.request(`/sa/courses/categories`, 'GET');
    }

    static async createCourse(data, files) {
        const formData = new FormData();
        for (const key in data) {
            if (data[key]) {
                if (typeof data[key] === 'object') {
                    formData.append(key, JSON.stringify(data[key]));
                } else {
                    formData.append(key, data[key]);
                }
            }
        }
        for (const key in files)
            if (files[key]) formData.append(key, files[key]);

        return await super.request(
            '/sa/courses',
            'POST',
            formData,
            { 'Content-Type': 'multipart/form-data' },
            false
        );
    }

    static async updateCourse(courseId, updates) {
        return await super.request(`/sa/courses/${courseId}`, 'PUT', updates);
    }

    static async addSection(courseId, parent, order) {
        return await super.request(`/sa/courses/${courseId}/sections`, 'POST', {
            parent,
            order,
        });
    }

    static async addContent(courseId, section, subSection, contentType) {
        return await super.request(`/sa/courses/${courseId}/contents`, 'POST', {
            section,
            subSection,
            contentType,
        });
    }

    static async removeSection(courseId, id, parent) {
        return await super.request(
            `/sa/courses/${courseId}/sections`,
            'DELETE',
            {
                parent,
                id,
            }
        );
    }

    static async removeContent(courseId, id, section, subSection) {
        return await super.request(
            `/sa/courses/${courseId}/contents`,
            'DELETE',
            {
                section,
                subSection,
                id,
            }
        );
    }

    static async reorderContent(courseId, id, oldPosition, newPosition) {
        return await super.request(
            `/sa/courses/${courseId}/contents/order`,
            'PUT',
            { id, oldPosition, newPosition }
        );
    }

    static async updateCourseTheme(courseId, data, files) {
        const formData = new FormData();
        formData.append('theme', JSON.stringify(data));

        for (const key in files)
            if (files[key]) formData.append(key, files[key]);

        return await super.request(
            `/sa/courses/${courseId}/theme`,
            'POST',
            formData,
            { 'Content-Type': 'multipart/form-data' },
            false
        );
    }

    static async addContentResource(courseId, file) {
        const formData = new FormData();
        formData.append('file', file);

        return await super.request(
            `/sa/courses/${courseId}/resource`,
            'POST',
            formData,
            { 'Content-Type': 'multipart/form-data' },
            false
        );
    }

    static async getCourseResources(courseId, type) {
        return super.request(
            `/sa/courses/${courseId}/resources/${type ?? 'all'}`
        );
    }
}
